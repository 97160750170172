import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {isEmpty} from 'lodash';

import MarketLayout from 'web/components/market_layout';
import Panel from 'web/components/panel';
import Alert from 'web/components/alert';
import MembershipCard from 'web/components/membership_card';
//  import MembershipModal from 'web/components/membership_modal';

import {reducer, actions} from './duck';
import AccountLayout from '../components/layout';
import SubscriptionSchedule from '../components/subscription_schedule';
import ToggleInput from '../components/toggle_input';

class AccountPage extends Component {
  static reducer(state, action) {
    let newState = MarketLayout.reducer(state, action);
    newState = reducer(newState, action);
    return newState;
  }

  constructor(props) {
    super(props);
    this.toggleNewsletter = this.toggleNewsletter.bind(this);
    this.toggleSMSDeliveryReminders = this.toggleSMSDeliveryReminders.bind(this);
    this.toggleShoppingReminders = this.toggleShoppingReminders.bind(this);
    this.toggleSubstitutions = this.toggleSubstitutions.bind(this);
    // this.state = {isMembershipModalOpen: false};
  }

  toggleNewsletter() {
    this.props.dispatch(actions.setNewsletterSubscribed(!this.props.user.newsletter));
  }

  toggleSubstitutions() {
    this.props.dispatch(actions.setSubstitutions(!this.props.user.substitutions));
  }

  toggleSMSDeliveryReminders() {
    this.props.dispatch(actions.setSMSDeliveryRemindersSubscribed(!this.props.user.sms.subscribed));
  }

  toggleShoppingReminders() {
    this.props.dispatch(
      actions.setShoppingRemindersSubscribed(!this.props.user.promptEmails.subscribed),
    );
  }

  renderPasswordSection() {
    const passwordUrl = '/account/password';

    return this.props.user.hasPassword === true ? (
      <div className="account-page__section">
        <a href={passwordUrl} className="account-page__button">
          Change
        </a>
        <h3 className="account-page__section-title">Password</h3>
        <div className="account-page__section-body">
          <div>Change your current password</div>
        </div>
      </div>
    ) : (
      <div className="account-page__section">
        <a href={passwordUrl} className="account-page__button is-warning">
          Add Password
        </a>
        <h3 className="account-page__section-title">Password</h3>
        <div className="account-page__section-body">
          <div>You don{"'"}t have a password yet.</div>
        </div>
      </div>
    );
  }

  renderPaymentSection() {
    return isEmpty(this.props.user.active_card) ? (
      <div className="account-page__section">
        <a href="/account/creditcard" className="account-page__button is-warning">
          Add Payment
        </a>
        <h3 className="account-page__section-title">Payment</h3>
        <div className="account-page__section-body">
          <div>You don{"'"}t have a payment method yet.</div>
        </div>
      </div>
    ) : (
      <div className="account-page__section">
        <a href="/account/creditcard" className="account-page__button">
          Change
        </a>
        <h3 className="account-page__section-title">Payment</h3>
        <div className="account-page__section-body">
          <div>
            {this.props.user.active_card.type} ending in {this.props.user.active_card.last4} and
            expiring {this.props.user.active_card.exp_month}/{this.props.user.active_card.exp_year}
          </div>
        </div>
      </div>
    );
  }

  renderOrderPreferenceSection() {
    return (
      <div className="account-page__section">
        <h3 className="account-page__section-title">Order Preferences</h3>
        {this.props.substitutionError ? this.renderError(this.props.substitutionError) : null}
        <div className="account-page__section-body">
          <ToggleInput
            title="Allow Substitutions"
            checked={this.props.user.substitutions}
            onToggle={this.toggleSubstitutions}
          >
            <em>
              Note: During the holiday season, regardless of your selection here, we will do our
              best to find an equal or better quality substitute.
            </em>
          </ToggleInput>
        </div>
      </div>
    );
  }

  renderEmailNotificationSection() {
    const subscribedToSMS = this.props.user.sms && this.props.user.sms.subscribed;
    const subscribedToShoppingReminders =
      this.props.user.promptEmails && this.props.user.promptEmails.subscribed;
    const subscribedToNewsletter = this.props.user.newsletter;

    return (
      <div className="account-page__section">
        <h3 className="account-page__section-title">Notification &amp; Email Preferences</h3>
        <div className="account-page__section-body">
          <ToggleInput
            title="SMS Delivery Reminders"
            className="account-page__toggle-sms-reminders"
            checked={subscribedToSMS}
            onToggle={this.toggleSMSDeliveryReminders}
          >
            We will send you a delivery reminder text right before your delivery.
          </ToggleInput>
          <ToggleInput
            title="Shopping Reminders"
            className="account-page__toggle-shopping-reminders"
            checked={subscribedToShoppingReminders}
            onToggle={this.toggleShoppingReminders}
          >
            Receive short, helpful reminders to place or add to your weekly order.
          </ToggleInput>
          {this.props.newsletterError ? this.renderError(this.props.newsletterError) : null}
          <ToggleInput
            title="News and Inspiration"
            className="account-page__toggle-newsletter"
            checked={subscribedToNewsletter}
            onToggle={this.toggleNewsletter}
          >
            Learn what{"'"}s new and in season, get helpful tips, hear about promotions, and more.
          </ToggleInput>
        </div>
      </div>
    );
  }

  renderSubscriptionScheduleSection() {
    if (!this.props.subscriptionSchedule || !this.props.subscriptionSchedule.deliveries.length) {
      return;
    }

    return (
      <div className="account-page__section">
        <a href="/account/schedule" className="account-page__button">
          Change
        </a>
        <h3 className="account-page__section-title">Subscriptions Schedule</h3>
        <div className="account-page__section-body">
          <SubscriptionSchedule />
        </div>
      </div>
    );
  }

  renderError({type, customerMessage, details} = {}) {
    let message, heading;
    switch (type) {
      case 'PREVIOUSLY_UNSUBSCRIBED':
        heading = 'Could not subscribe';
        message = (
          <span>
            You have already unsubscribed from our mailing list.&nbsp;
            <a target="_blank" rel="noopener" href={details.resubscribeLink}>
              Please click here to re-subscribe.
            </a>
          </span>
        );
        break;
      default:
        heading = 'Something went wrong';
        message = customerMessage || 'An error occurred trying to update your account.';
    }
    return (
      <Alert className="account-page__error" heading={heading} type="error">
        {message}
      </Alert>
    );
  }

  // Disable membership modal https://goodeggs.atlassian.net/browse/ECOMM-36
  /* handleTriggerMembershipModal() {
    this.setState({isMembershipModalOpen: open});
  }*/

  render() {
    return (
      <MarketLayout
        categories={this.props.categories}
        foodhub={this.props.foodhub}
        user={this.props.user}
        disableUpcomingOrdersBanner
      >
        <Helmet>
          <title>Account | Good Eggs</title>
        </Helmet>

        <div className="account-page">
          <AccountLayout slug="" isMember={this.props.user?.membership?.isMember}>
            {this.props.confirmChanges && (
              <Alert
                className="account-page__alert"
                type="confirmation"
                heading="Your account details have been updated."
              >
                <a className="account-page__link" href="/">
                  Shop the market.
                </a>
              </Alert>
            )}

            {this.props.user?.membership?.isMember && (
              <MembershipCard
              // onClickInfo={() => this.handleTriggerMembershipModal(true)}
              />
            )}

            <Panel header="Your Account">
              <div className="account-page__section">
                <a href="/account/details" className="account-page__button">
                  Change
                </a>
                <h3 className="account-page__section-title">Account Details</h3>
                <div className="account-page__section-body">
                  <div className="account-page__name">
                    {this.props.user.firstName} {this.props.user.lastName ?? ''}
                  </div>
                  <div className="account-page__email">{this.props.user.email}</div>
                  <div>{this.props.user.phone}</div>
                </div>
              </div>
              {this.renderPasswordSection()}
              {this.renderPaymentSection()}
              {this.renderOrderPreferenceSection()}
              {this.renderEmailNotificationSection()}
              {this.renderSubscriptionScheduleSection()}
            </Panel>
          </AccountLayout>
        </div>
        {
          // Disable membership modal https://goodeggs.atlassian.net/browse/ECOMM-36
        }
        {/* {this.props.user?.membership?.isMember && this.state.isMembershipModalOpen && (*/}
        {/*  <MembershipModal onCloseModal={() => this.handleTriggerMembershipModal(false)} />*/}
        {/* )}*/}
      </MarketLayout>
    );
  }
}

AccountPage.pageName = 'Account';

export default connect((state) => state)(AccountPage);
